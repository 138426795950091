import moment from "moment";

export const getTimezoneOffset = (timeZoneIANA: string) => {
  return `UTC${moment.tz(timeZoneIANA).format("Z")}`;
};

export const getTimezoneNamesWithOffset = () => {
  const rawTimeZoneNames = moment.tz.names();
  return rawTimeZoneNames.map(
    (tzName) => `${tzName} (${getTimezoneOffset(tzName)})`
  );
};

export const stripTimezoneOffset = (timezone: string = "") => {
  const tzTokens = timezone.split(" (UTC ");

  return tzTokens[0];
};

export const getDatetimeOnTz = (date: Date, timezone: string) => {
  const difference = moment.tz.zone(timezone)?.offset(moment.now());

  if (difference && difference >= 0) {
    return moment(date).add(difference, "minutes").toDate();
  } else {
    return moment(date).subtract(difference, "minutes").toDate();
  }
};
